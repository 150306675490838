import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import OnPreoPaperBackground from "../layout/onpreo-paper-background";
import { useStyles } from "../layout/styles";
import { ArrowForwardIosOutlined, CompareArrowsOutlined, HomeOutlined, LocationOnOutlined, Timeline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
import { setCardState } from "src/store/slices/card-value.slice";
import { useSelector } from "react-redux";
import { getAskingPrice, getLivingArea, getPriceAdjustmentFactor, getRealEstate, getRealEstateCat } from "src/store/selectors/real-estate.selectors";
import { useAppDispatch } from "../../../guest-app/src/store/index";
import { setProperty } from "src/store/slices/property.slice";
import { getRealEstateCategoryMap } from "./price-rules/rules";
import { useReduxSelector } from "src/store/selectors";
import { setRealEstate } from "src/store/slices/real-estate.slice";
import { CurrencyShort, parseLocaleNumber } from "src/utils/formatters";
import { setSideBarState } from "src/store/slices/sideBar-value.slice";
import { getBooleanSideBarValue } from "src/store/selectors/sideBar-value.selector";
import MenuIcon from "@material-ui/icons/Menu";
import { getSlideChange } from "src/store/selectors/change-on-slide.selectors";
import sumOfArray from "./helper/arithmetic-functions";
import { parseAddress } from "../../../broker-app/src/utils/formatters";

const useStyle = makeStyles(theme =>
    createStyles({
        paper: {
            backgroundColor: "transparent",
            boxShadow: "none"
        },
        icon: { width: 76, height: 76, color: "#0071E3" },
        button: {
            backgroundColor: (props: { primary: string }) => (props?.primary ? props?.primary : "#273340"),
            "&:hover": {
                backgroundColor: (props: { primary: string }) => (props?.primary ? props?.primary : "#3898EC")
            }
        },
        iPad: {
            width: "100%",
            minWidth: 1400,
            marginLeft: "-30%",
            "@media (max-width: 1024px)": {
                marginLeft: "-5%",
                "@media (orientation:landscape)": { width: 964, minWidth: 700 },
                "@media (orientation:portrait)": { width: 700, minWidth: 700, marginLeft: 0 }
            }
        },
        iPadText: {
            fontWeight: 600,
            fontSize: 20,
            "@media (max-width: 1024px)": {
                fontSize: 14
            }
        }
    })
);

const PageIntro = ({ handleNext, initialState }) => {
    const classes = { ...useStyles(), ...useStyle({ primary: initialState?.workspace?.publication?.primary }) };
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const state = useReduxSelector(getRealEstate);
    const propertyType = useSelector(getRealEstateCat);
    const askingPrice = useSelector(getAskingPrice);
    const marketFactor = initialState?.marketPriceAdjustmentFactor ?? state?.workspace?.extras?.globalPriceAdjustmentFactor?.[propertyType];
    const [cardValue, setCardValue] = useState(
        state.askingPrice.estimate ?? initialState.askingPrice.estimate ?? initialState.priceAssistant?.startPrice?.value
    );
    const livingArea = useSelector(getLivingArea) ?? initialState.livingArea;
    const [sprengnetterPrice, setSprengnetterPrice] = useState(state?.askingPrice?.dossier?.value ?? state.askingPrice.evaluation);
    const displayPrice =
        cardValue !== undefined
            ? CurrencyShort(cardValue)
            : marketFactor
            ? CurrencyShort((sprengnetterPrice * (marketFactor / 100)) / livingArea)
            : CurrencyShort(sprengnetterPrice / livingArea);

    const slideChangeState = useReduxSelector(getSlideChange);
    const slideChangeValues = Object.values(slideChangeState);
    const adress = state?.address;
    delete initialState.address._id;

    useEffect(() => {
        setSprengnetterPrice(state?.askingPrice?.dossier?.value || state.askingPrice.evaluation || state.askingPrice.estimate);
    }, [state]);

    const iconStyle = { width: 76, height: 76, color: initialState?.workspace?.publication?.primary ?? "#0071E3" };

    const steps = [
        { icon: <LocationOnOutlined style={iconStyle} />, name: "Lage" },
        { icon: <HomeOutlined style={iconStyle} />, name: "Immobilie" },
        { icon: <CompareArrowsOutlined style={iconStyle} />, name: "Marktvergleich" },
        { icon: <Timeline style={iconStyle} />, name: "Verkaufsstrategie" }
    ];

    const updatePageIntro = async data => {
        dispatch(setProperty({ type: getRealEstateCategoryMap(state.category) }));
        const brokerSetPrice = parseLocaleNumber(displayPrice);
        const finalPrice = brokerSetPrice ?? sprengnetterPrice / livingArea;
        dispatch(setRealEstate({ ...state, askingPrice: { ...askingPrice, estimate: finalPrice } }));
        dispatch(setCardState({ value: finalPrice * livingArea + sumOfArray(slideChangeValues) }));
    };
    const handleForward = async data => {
        updatePageIntro(data);
        handleNext();
    };

    const sideBarState = useSelector(getBooleanSideBarValue);

    const openSideBar = async data => {
        updatePageIntro(data);
        dispatch(setSideBarState({ value: !sideBarState }));
    };

    return (
        <form>
            <Fragment>
                <Grid item justifyContent={"center"} style={{ display: "flex" }}>
                    <OnPreoPaperBackground className={classes.paper}>
                        <Grid container className={classes.iPad}>
                            <div className={classes.root}>
                                <Grid container direction="column" justifyContent="center">
                                    <Grid item justifyContent="center" className={classes.grid}>
                                        <Typography variant="h1" align="center" color={"textPrimary"}>
                                            Bewertung & Verkaufsstrategie
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" className={classes.grid} style={{ marginTop: -8 }}>
                                        <Typography align="center" color={"textPrimary"} style={{ fontWeight: 400, fontSize: 20 }}>
                                            für die Immobilie in {parseAddress(adress)}{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item direction="row" justifyContent={"center"} className={classes.grid} style={{ margin: "72px 0 96px 0" }}>
                                        {steps.map((item, index) => (
                                            <Fragment key={item.name}>
                                                <Grid item container xs={12} direction="column" justifyContent="center" alignItems={"center"}>
                                                    {item.icon}
                                                    <Typography className={classes.iPadText}>
                                                        <span style={{ fontWeight: 600, color: "orange" }}>{`${index + 1}. `}</span>
                                                        {`${item.name}`}
                                                    </Typography>
                                                </Grid>
                                                {index < steps.length - 1 && (
                                                    <Grid container justifyContent="center" alignItems={"center"}>
                                                        <ArrowForwardIosOutlined style={{ width: 44, height: 44, color: theme.palette.grey[300] }} />
                                                    </Grid>
                                                )}
                                            </Fragment>
                                        ))}
                                    </Grid>
                                    <Grid container item direction="row" alignItems="flex-end" justifyContent={"space-between"}>
                                        <Grid item xs={6} justifyContent="flex-start" style={{ display: "flex" }}>
                                            {/*<Button variant="contained" color="secondary" disabled>*/}
                                            {/*    Zurück*/}
                                            {/*</Button>*/}
                                        </Grid>
                                        <Grid item xs={12} justifyContent="center" style={{ display: "flex" }}>
                                            <Button variant="contained" color="primary" className={classes.button} onClick={handleForward}>
                                                Starten
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </OnPreoPaperBackground>
                </Grid>
            </Fragment>
            <IconButton color={"secondary"} onClick={openSideBar} classes={{ root: classes.menuIconPriceFinder }}>
                <Box component={MenuIcon} width="2rem!important" height="2rem!important" />
            </IconButton>
        </form>
    );
};

export default PageIntro;
