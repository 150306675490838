import { createStyles, makeStyles, Grid, LinearProgress, Theme, Typography } from "@material-ui/core";
import { IndexNavbar } from "@onpreo/components/src/navigation/navbar/index-navbar";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RealEstateProps } from "../functions/props-factory";
import { getPriceCardValue } from "../store/selectors/card-value.selector";
import { getForward } from "../store/selectors/page-history.selectors.ts";
import {
    getPotentialBuyers,
    getLogoUseInPriceAssistant,
    getWorkspaceLogo,
    getLivingArea,
    getPriceAdjustmentFactor,
    getRealEstateConstruction
} from "../store/selectors/real-estate.selectors";
import { setBrokerEntryState } from "../store/slices/broker-entry.slice";
import { setPageHistoryState } from "../store/slices/page-history.slice";
import { setRealEstate } from "../store/slices/real-estate.slice";
import AnimationLoader from "./helper/animation-loader";
import {
    GENERAL_STEPS,
    EXTRA_INFO_CARDS,
    SKIP_STEP_HAUS,
    SKIP_STEP_WOHNUNG,
    SKIP_STEP_MEHRFAMILIENHAUS,
    numberOfEFHSlides,
    getStepContentIntro,
    numberOfETWSlides,
    numberOfMFHSlides,
    getStepContentWohnung,
    getStepContentMehrfamilienhaus
} from "./helper/component-loader";
import PriceCard from "./helper/price-card";
import { LoadingButton } from "@onpreo/components";
import { PROTOCOL, BROKER_APP_URL, FEATURE_SIDEBAR, PRICE_ASSISTANT_URL } from "src/utils/secrets";
import { useRouter } from "next/router";
import { parseLocaleNumber, CurrencyShort } from "src/utils/formatters";
import { setCardState } from "src/store/slices/card-value.slice";
import { getCompletedSteps } from "../store/selectors/completed-steps.selectors";
import { setCompletedStepsState } from "src/store/slices/completed-steps.slice";
import { getStepContentHaus } from "./helper/component-loader";
import { RealEstateConstruction } from "@onpreo/database/src/definitions/real-estate-helpers";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        div: {
            flexGrow: 1,
            overflow: "visible",
            display: "flex",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "space-around",
            "@media (max-width: 1024px)": {
                "@media (orientation:landscape)": { marginTop: -30, overFlowX: "hidden" }
            }
        },
        container: {
            minHeight: 400,
            width: "100%"
        },
        card1: {
            width: "47%",
            height: 150,
            background: "linear-gradient(to right, #F5365C, #F56636)",
            boxShadow: muiTheme.shadows[8],
            color: "#FFFFFF",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: 10
        },
        card2: {
            width: "47%",
            height: 150,
            background: "linear-gradient(to right, #00bfff, #007FFF)",
            boxShadow: muiTheme.shadows[8],
            color: "#FFFFFF",
            justifyContent: "center",
            padding: 10
        },
        grid: {
            display: "flex",
            width: "100%",
            marginRight: "auto",
            marginLeft: 15,
            paddingTop: 15
        },
        root: {
            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#FB6340"
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#FB6340"
            }
        },
        progress: {
            width: "40%",
            height: 5,
            borderRadius: 5,
            [`&. .MuiLinearProgress-colorPrimary`]: {
                backgroundColor: "#E9ECEF"
            },
            [`& .MuiLinearProgress-bar`]: {
                borderRadius: 5,
                // backgroundColor: "#F56636"
                backgroundColor: (props: { primary: string }) => (props?.primary ? props?.primary : "#0071E3")
            }
        }
    })
);

const PriceAssistentLoader: FC<RealEstateProps> = ({ ...props }) => {
    const classes = useStyles({ primary: props?.workspace?.publication?.primary });
    const state = { ...props };
    delete state.id;
    const router = useRouter();
    const [activeStep, setActiveStep] = useState(0);
    const documentId = props.id;
    const realEstateCategory = state?.category;
    const categoryToPropertyType = {
        MFH: "Mehrfamilienhaus",
        EFH: "Haus",
        ETW: "Wohnung"
    };
    const propertyType = categoryToPropertyType[realEstateCategory] || "";
    const dispatch = useDispatch();
    const potentialBuyers = useSelector(getPotentialBuyers);

    const extrasLogoUse = useSelector(getLogoUseInPriceAssistant);
    const extrasLogo = useSelector(getWorkspaceLogo);
    const imgSrc = extrasLogoUse && extrasLogo?.src !== undefined ? extrasLogo?.src : "/logo.png";

    const completedSteps = useSelector(getCompletedSteps);

    const noPriceCardIndexes = [0, 1, 2, 3, 4, 5];
    const noPriceCardIndexKapitalAnlegerEFH = potentialBuyers?.kapitalAnleger ? SKIP_STEP_HAUS + 2 : SKIP_STEP_HAUS + 1;
    const noPriceCardIndexKapitalAnlegerMFH = potentialBuyers?.kapitalAnleger ? SKIP_STEP_MEHRFAMILIENHAUS + 2 : SKIP_STEP_MEHRFAMILIENHAUS + 1;
    const noPriceCardIndexKapitalAnlegerETW = potentialBuyers?.kapitalAnleger ? SKIP_STEP_WOHNUNG + 2 : SKIP_STEP_WOHNUNG + 1;
    let noPriceCardIndexKapitalAnleger: number;

    if (propertyType === "Haus") {
        noPriceCardIndexKapitalAnleger = noPriceCardIndexKapitalAnlegerEFH;
        noPriceCardIndexes.push(SKIP_STEP_HAUS - 1);
        noPriceCardIndexes.push(SKIP_STEP_HAUS + 2);
        noPriceCardIndexes.push(SKIP_STEP_HAUS + 3);
        noPriceCardIndexes.push(SKIP_STEP_HAUS + 4);
        noPriceCardIndexes.push(SKIP_STEP_HAUS + 5);
        noPriceCardIndexes.push(SKIP_STEP_HAUS + 6);
        noPriceCardIndexes.push(SKIP_STEP_HAUS + 7);
    } else if (propertyType === "Wohnung") {
        noPriceCardIndexKapitalAnleger = noPriceCardIndexKapitalAnlegerETW;
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG - 1);
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG + 2);
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG + 3);
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG + 4);
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG + 5);
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG + 6);
        noPriceCardIndexes.push(SKIP_STEP_WOHNUNG + 7);
    } else if (propertyType === "Mehrfamilienhaus") {
        noPriceCardIndexKapitalAnleger = noPriceCardIndexKapitalAnlegerMFH;
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS - 1);
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS + 2);
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS + 3);
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS + 4);
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS + 5);
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS + 6);
        noPriceCardIndexes.push(SKIP_STEP_MEHRFAMILIENHAUS + 7);
    }

    const [helperRefresh, setHelperRefresh] = useState(false);

    useEffect(() => {
        if (!completedSteps.includes(0)) {
            const realEstateId = router.query.re;
            const tkn = router.query.auth;
            setActiveStep(0);
            dispatch(setCompletedStepsState({ completedStepsArray: [0] }));
            router.push(`${PROTOCOL}${PRICE_ASSISTANT_URL}/?re=${realEstateId}&auth=${tkn}&activeStep=${0}`);
            setHelperRefresh(true);
        }
    }, [completedSteps]);

    useEffect(() => {
        if (helperRefresh) {
            if (FEATURE_SIDEBAR) {
                const realEstateId = router.query.re;
                const tkn = router.query.auth;
                router.push(`${PROTOCOL}${PRICE_ASSISTANT_URL}/?re=${realEstateId}&auth=${tkn}&activeStep=${activeStep}`);
            }
        }
    }, [activeStep]);

    const [helper, setHelper] = useState(true);

    useEffect(() => {
        if (helperRefresh) {
            if (activeStep === 0 && helper) {
                dispatch(setRealEstate(state));
                dispatch(setBrokerEntryState(state.askingPrice.brokerEntries));
                FEATURE_SIDEBAR ? setHelper(false) : null;
            }
            if (FEATURE_SIDEBAR) {
                if (router.query.activeStep !== undefined) {
                    setActiveStep(Number(router.query.activeStep));
                }
            }
        }
    }, [router.query.activeStep]);

    const stepsHaus = numberOfEFHSlides;
    const stepsWohnung = numberOfETWSlides;
    const stepsMehrfamilienhaus = numberOfMFHSlides;
    const forward = useSelector(getForward);
    const construction = useSelector(getRealEstateConstruction);
    const cardValue = useSelector(getPriceCardValue);
    const livingArea = useSelector(getLivingArea) ?? state.livingArea;
    const marketFactor = useSelector(getPriceAdjustmentFactor) ?? state?.workspace?.extras?.globalPriceAdjustmentFactor?.[realEstateCategory];
    const initialCardValue = useState(
        state.askingPrice.estimate !== 0 && !state.askingPrice.estimate && state.askingPrice.estimate
            ? state.askingPrice.estimate
            : state.priceAssistant?.startPrice?.value
    );
    const [sprengnetterPrice, setSprengnetterPrice] = useState(state?.askingPrice?.dossier?.value ?? state.askingPrice.evaluation);
    if (sprengnetterPrice === 0) setSprengnetterPrice(state.askingPrice.estimate * livingArea);

    useEffect(() => {
        if (cardValue === 0 || !cardValue || isNaN(cardValue)) {
            const displayPrice =
                initialCardValue !== undefined
                    ? CurrencyShort(initialCardValue)
                    : marketFactor
                    ? CurrencyShort((sprengnetterPrice * (marketFactor / 100)) / livingArea)
                    : CurrencyShort(sprengnetterPrice / livingArea);
            const cardValueString = String(displayPrice);
            const brokerSetPrice = parseLocaleNumber(cardValueString);
            const finalPrice = !!brokerSetPrice && brokerSetPrice !== 0 ? brokerSetPrice : sprengnetterPrice / livingArea;
            const calculatedCardValue = finalPrice * livingArea;
            dispatch(setCardState({ value: calculatedCardValue }));
            dispatch(setRealEstate(state));
            dispatch(setBrokerEntryState(state.askingPrice.brokerEntries));
        }
    }, []);

    const handleForward = construction => {
        dispatch(setPageHistoryState({ forward: true }));
        if (propertyType === "Haus" && activeStep === SKIP_STEP_HAUS) {
            if (potentialBuyers?.kapitalAnleger) setActiveStep(activeStep + 1);
            else setActiveStep(activeStep + 2);
        } else if (propertyType === "Wohnung" && activeStep === SKIP_STEP_WOHNUNG) {
            if (potentialBuyers?.kapitalAnleger) setActiveStep(activeStep + 1);
            else setActiveStep(activeStep + 2);
        } else if (propertyType === "Mehrfamilienhaus" && activeStep === SKIP_STEP_MEHRFAMILIENHAUS) {
            if (potentialBuyers?.kapitalAnleger) setActiveStep(activeStep + 1);
            else setActiveStep(activeStep + 2);
        } else setActiveStep(activeStep + 1);
    };

    const handleBackward = () => {
        dispatch(setPageHistoryState({ forward: false }));
        if (propertyType === "Haus" && activeStep === SKIP_STEP_HAUS + 2) {
            if (potentialBuyers?.kapitalAnleger) setActiveStep(activeStep - 1);
            else setActiveStep(activeStep - 2);
        } else if (propertyType === "Wohnung" && activeStep === SKIP_STEP_WOHNUNG + 2) {
            if (potentialBuyers?.kapitalAnleger) setActiveStep(activeStep - 1);
            else setActiveStep(activeStep - 2);
        } else if (propertyType === "Mehrfamilienhaus" && activeStep === SKIP_STEP_MEHRFAMILIENHAUS + 2) {
            if (potentialBuyers?.kapitalAnleger) setActiveStep(activeStep - 1);
            else setActiveStep(activeStep - 2);
        } else setActiveStep(activeStep - 1);
    };

    const handleRedirect = () => {
        window.location.assign(`${PROTOCOL}${BROKER_APP_URL}/real-estate/${documentId}?tab=information`);
    };

    return (
        <div style={{ width: "98%" }}>
            <IndexNavbar logo={{ imgSrc, imgAlt: "onpreo" }} style={{ zIndex: -1 }} />
            <Grid container justifyContent={"flex-end"} style={{ position: "absolute", right: 25, top: 10, zIndex: 99 }}>
                <LoadingButton variant="contained" color="primary" onClick={handleRedirect}>
                    Zurück zur Immobilie
                </LoadingButton>
            </Grid>
            <div className={classes.div}>
                <Grid container item xs={12} direction="column" style={{ width: "100%", display: "grid", paddingTop: 30 }}>
                    <Grid item style={{ paddingTop: 15, display: "flex" }} justifyContent="center">
                        <Typography align="left" variant={"body2"} style={{ width: "40%" }}>
                            Fortschritt
                        </Typography>
                    </Grid>
                    {activeStep <= GENERAL_STEPS && (
                        <Grid item style={{ display: "flex", paddingTop: 5 }} justifyContent="center">
                            <LinearProgress
                                className={classes.progress}
                                variant="determinate"
                                value={((activeStep + 1) / (GENERAL_STEPS + stepsHaus + EXTRA_INFO_CARDS)) * 100}
                                style={{ color: "red", backgroundColor: "red" }}
                            />
                        </Grid>
                    )}
                    {activeStep > GENERAL_STEPS && propertyType === "Haus" && (
                        <Grid item style={{ display: "flex", paddingTop: 5 }} justifyContent="center">
                            <LinearProgress
                                className={classes.progress}
                                variant="determinate"
                                value={((activeStep + 1) / (GENERAL_STEPS + stepsHaus + EXTRA_INFO_CARDS - 2)) * 100}
                            />
                        </Grid>
                    )}
                    {activeStep > GENERAL_STEPS && propertyType === "Wohnung" && (
                        <Grid item style={{ display: "flex", paddingTop: 5 }} justifyContent="center">
                            <LinearProgress
                                className={classes.progress}
                                variant="determinate"
                                value={((activeStep + 1) / (GENERAL_STEPS + stepsWohnung + EXTRA_INFO_CARDS - 2)) * 100}
                            />
                        </Grid>
                    )}
                    {activeStep > GENERAL_STEPS && propertyType === "Mehrfamilienhaus" && (
                        <Grid item style={{ display: "flex", paddingTop: 5 }} justifyContent="center">
                            <LinearProgress
                                className={classes.progress}
                                variant="determinate"
                                value={((activeStep + 1) / (GENERAL_STEPS + stepsMehrfamilienhaus + EXTRA_INFO_CARDS - 2)) * 100}
                            />
                        </Grid>
                    )}
                    {!noPriceCardIndexes.includes(activeStep) && activeStep !== noPriceCardIndexKapitalAnleger && (
                        <Grid item style={{ display: "flex", paddingTop: 5 }} justifyContent="center">
                            <PriceCard cardValue={cardValue} activeStep={activeStep} />
                        </Grid>
                    )}
                    <div style={{ width: "100%" }}>
                        <AnimationLoader childKey={activeStep} isForward={forward}>
                            {activeStep <= GENERAL_STEPS && getStepContentIntro(handleForward, handleBackward, state, documentId, activeStep)}
                            {activeStep > GENERAL_STEPS &&
                                propertyType === "Haus" &&
                                getStepContentHaus(handleForward, handleBackward, state, documentId, activeStep)}
                            {activeStep > GENERAL_STEPS &&
                                propertyType === "Wohnung" &&
                                getStepContentWohnung(handleForward, handleBackward, state, documentId, activeStep)}
                            {activeStep > GENERAL_STEPS &&
                                propertyType === "Mehrfamilienhaus" &&
                                getStepContentMehrfamilienhaus(handleForward, handleBackward, state, documentId, activeStep)}
                        </AnimationLoader>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

export default PriceAssistentLoader;
